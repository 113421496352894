import React from "react"
import ModelPreview from "../../components/Builder/ModelPreview"
import ArticlePageTemplate from "../../templates/ArticlePage.jsx"

const StoryPage = () => {
  return <ModelPreview  model="article" contentLoaded={(pageContext) => {
    return <ArticlePageTemplate pageContext={pageContext} />
  }} />
}

export default StoryPage